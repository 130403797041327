
<template>
    <div>
        <div class="result-panel">
            <div class="form-item">
                <div class="form-item-label">开放平台</div>
                <div class="form-item-content">
                    <CSSelect height="40px">
                        <select v-model="config.vendor">
                            <option value="" disabled>请选择</option>
                            <option
                                v-for="(vendor, index) in vendorList"
                                :key="index"
                                :value="vendor.val"
                            >
                                {{ vendor.name }}
                            </option>
                        </select>
                    </CSSelect>
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-label">开放平台AppId</div>
                <div class="form-item-content">
                    <input
                        v-model="config.appId"
                        type="text"
                        placeholder="请输入"
                        class="cs-input"
                    />
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-label">开放平台AppKey</div>
                <div class="form-item-content">
                    <input
                        v-model="config.appKey"
                        type="text"
                        placeholder="请输入"
                        class="cs-input"
                    />
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-label">开放平台AppSecret</div>
                <div class="form-item-content">
                    <input
                        v-model="config.appSecret"
                        type="text"
                        placeholder="请输入"
                        class="cs-input"
                    />
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-label"></div>
                <div class="form-item-content">
                    <button class="btn btn-primary" @click="saveConfig">
                        保存
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CSSelect from "@/components/common/CSSelect";
import {
    setupVendorConfigUrl,
    editVendorConfigUrl,
    queryAttendanceVendorUrl,
} from "@/requestUrl";
export default {
    props: {},
    components: { CSSelect },
    data() {
        return {
            vendorList: [{ val: "DINGTALK", name: "钉钉" }],
            isEdit: false,
            config: {
                vendor: "DINGTALK",
                appId: "",
                appKey: "",
                appSecret: "",
                regionId: this.$vc.getCurrentRegion().communityId,
            },
        };
    },

    mounted() {
        this.queryVendorConfig();
    },
    methods: {
        verifyConfigParams() {
            return this.$vc.validate.validate(
                {
                    configInfo: this.config,
                },
                {
                    "configInfo.vendor": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "请选择平台",
                        },
                    ],
                    "configInfo.appId": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "appId不能为空",
                        },
                    ],
                    "configInfo.appKey": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "appKey不能为空",
                        },
                    ],
                    "configInfo.appSecret": [
                        {
                            limit: "required",
                            param: "",
                            errInfo: "appSecret不能为空",
                        },
                    ],
                }
            );
        },
        queryVendorConfig() {
            this.$fly
                .get(queryAttendanceVendorUrl, {
                    regionId: this.$vc.getCurrentRegion().communityId,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.config = {
                        vendor: res.data.vendor,
                        appId: res.data.appId,
                        appKey: res.data.appKey,
                        appSecret: res.data.appSecret,
                        regionId: this.$vc.getCurrentRegion().communityId,
                    };
                    this.isEdit = true;
                });
        },
        saveConfig() {
            let params = {};
            for (let key in this.config) {
                params[key] = this.config[key];
            }
            if (this.isEdit) {
                delete params.regionId;
            }
            if (!this.verifyConfigParams()) {
                this.$vc.toast(this.$vc.validate.errInfo);
                return;
            }
            this.$fly
                .post(
                    this.isEdit ? editVendorConfigUrl : setupVendorConfigUrl,
                    params
                )
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.$vc.toast("设置成功");
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
.result-panel
    padding-top 30px
    min-height 600px
    .btn
        font-size 24px
        width 120px
.form-item
    display flex
    flex-flow row nowrap
    align-items center
    font-size 24px
    &-label
        width 280px
        text-align right
        margin-right 30px
        color #444
    &-content
        width 500px
        input
            width 500px
            border 1px solid #979797
            border-radius 4px
            outline none
            height 40px
            padding 0 5px
        select
            width 500px
    &:not(:last-of-type)
        margin-bottom 20px
</style>
    